import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem } from "../styles/utils";
import Image from "./image";

export const CPImageBox = styled.div(
  ({ maxWidth }) => css`
    position: relative;
    max-width: ${rem(maxWidth)};
    margin: 0 auto ${rem(85)};
  `
);

export const CPVImg = styled(Image)(
  ({ presentationWidth }) => css`
    max-width: ${presentationWidth || "100%"};
    margin: 0 auto;
  `
);

export const bubbleSmall = css``;

export const bubble1 = css`
  position: absolute;
  width: ${rem(200)};
  height: ${rem(200)};
  left: 0;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: -1;
`;

export const bubble2 = css`
  ${bubble1};
  left: auto;
  right: 0;
  transform: translate3d(50%, -50%, 0);
`;

export const bubble3 = css`
  position: absolute;
  width: ${rem(64)};
  height: ${rem(64)};
  right: ${rem(-42)};
  bottom: ${rem(-22)};
  z-index: -1;
`;

export const bubble4 = css`
  margin: ${rem(40)} auto;
  width: ${rem(64)};
  height: ${rem(64)};
`;

export const cpHero = css`
  margin-top: ${rem(89)};
`;

export const contentBox = css`
  padding-top: 0;
`;
