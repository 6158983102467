import React from "react";
import { useChallengeHeroData } from "../hooks/use-challenge-hero";

import Image from "./image";
import HeroBlock from "./hero-block";
import QuoteBlock from "./quote-block";
import ChallengeCardList from "./challenges-card-list";

import * as SShared from "../styles/shared";
import * as SPages from "./page.styles";
import * as S from "./page-challenge.styles";

const SS = { ...SShared, ...SPages };

const VideoImage = ({ image, color }) => (
  <S.CPImageBox
    maxWidth={image.childImageSharp.fluid.presentationWidth}
    aspectRatio={image.childImageSharp.fluid.aspectRatio}
  >
    <Image data={image} />
    <SS.Circle css={S.bubble1} color={color} />
    <SS.Circle css={S.bubble2} color={color} />
    <SS.Circle css={S.bubble3} color={color} />
  </S.CPImageBox>
);

const PageChallenge = ({
  heroBlock,
  color,
  videoImage,
  summary,
  html,
  slug
}) => (
  <article>
    <SS.PageHeader css={SS.clipBox} as="header">
      {videoImage && <VideoImage image={videoImage} color={color} />}
      <SS.PageTitle as="p">{summary}</SS.PageTitle>
    </SS.PageHeader>
    {html && (
      <>
        <SS.Circle css={S.bubble4} color={color} />
        <SS.PageContent
          css={S.contentBox}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </>
    )}
    <HeroBlock {...heroBlock} big />
    <QuoteBlock />
    <ChallengeCardList title={"More Challenges"} excluded={[slug]} />
  </article>
);

export default data => {
  const heroBlock = useChallengeHeroData();
  return <PageChallenge {...data} heroBlock={heroBlock} />;
};
