import React, { memo } from "react";
import { usePlaceholderImages } from "../hooks/use-placeholder";

import * as SS from "../styles/shared";
import * as S from "./quote-block.styles";

const QuoteBlock = () => {
  const { avatar } = usePlaceholderImages();

  return (
    <S.QuoteBox as="blockquote">
      <S.QuoteImg data={avatar} />
      <S.QuoteTxt>
        “Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt officia deserunt mollit anim laborum.”
      </S.QuoteTxt>
      <S.QuoteCite>
        <b css={SS.bold}>Marc Beritz</b> / Title, Location
      </S.QuoteCite>
    </S.QuoteBox>
  );
};

export default memo(QuoteBlock);
