import styled from "@emotion/styled";
// import { css } from "@emotion/core";
import { rem, fontSize, fontWeights } from "../styles/utils";

import Image from "./image";
import { Container } from "../styles/shared";

export const QuoteImg = styled(Image)`
  border-radius: 100%;
  margin-bottom: ${rem(40)};
`;

export const QuoteBox = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: ${rem(960)};
  padding-top: ${rem(50)};
  padding-bottom: ${rem(45)};
  margin-top: ${rem(50)};
  margin-bottom: ${rem(15)};
`;

export const QuoteTxt = styled.p`
  ${fontSize(26, 40)};
  font-style: normal;
  font-weight: ${fontWeights.book};
  margin-top: 0;
  margin-bottom: ${rem(35)};
`;

export const QuoteCite = styled.cite`
  ${fontSize(18, 20)};
  font-style: normal;
`;
