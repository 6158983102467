import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";

import { Container } from "../styles/shared";
import BackgroundImage from "gatsby-background-image";

const bigBox = css`
  padding-bottom: 0;
  align-items: center;
  justify-content: center;
  min-height: ${rem(500)};
`;

export const HeroBG = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(90)};
  min-height: ${rem(360)};
  ${({ big }) => big && bigBox};
`;

export const HeroContent = styled(Container)`
  ${({ big }) =>
    !big &&
    css`
      margin-bottom: 0;
      margin-top: auto;
    `};
  text-align: center;
  color: ${colors.white};
`;

export const HeroTitle = styled.h2`
  max-width: ${rem(840)};
  margin-bottom: ${rem(40)};
  ${({ color }) =>
    color &&
    css`
      color: ${colors[color]};
    `};
  ${fontSize(40, 50)};
  ${({ big }) => big && fontSize(60, 70)};
  font-weight: ${fontWeights.medium};
`;
