import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights, colors, hexToRGBA } from "../styles/utils";

const { white } = colors;

const gradient = ({ color }) => {
  const rgba = hexToRGBA(color.replace(/^#/, ""));
  return rgba
    ? css`
        background-image: conic-gradient(
          from 270deg at 50% 50%,
          ${hexToRGBA(color, 0)},
          ${rgba}
        );
      `
    : ``;
};

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CardBg = styled.div`
  ${cover};
  ${gradient};
  pointer-events: none;
  z-index: 3;
`;

export const cardBgImg = css`
  ${cover};
  position: absolute !important; /* override gatsby-image */
  z-index: 1;
`;

export const CardImgOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  z-index: 2;
`;

const flipped = ({ hidden }) => css`
  ${hidden && "opacity: 0; z-index: -5;"}
`;

export const CardSide = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  ${flipped};
  background-color: ${white};
`;

export const Card = styled.article`
  position: relative;
  border-radius: ${rem(30)};
  overflow: hidden;
  width: ${rem(300)};
  height: ${rem(360)};
  border-radius: ${rem(30)};
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${rem(30)} ${rem(20)} ${rem(10)};
`;

export const CardTitleBox = styled.h2`
  position: relative;
  height: 50%;
  padding: ${rem(16)} ${rem(20)};
  margin-top: auto;
  margin-bottom: 0;
  overflow-y: auto;
  z-index: 5;
  color: ${white};
`;

export const CardTitle = styled.span`
  display: block;
  ${fontSize(26, 30)};
  font-weight: ${fontWeights.book};
  margin-bottom: ${rem(6)};
`;

export const CardSGD = styled.span`
  display: block;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.bold};
`;

export const headingTxt = css`
  display: block;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
  margin: 0;
`;

export const challengeTxt = css`
  display: block;
  height: 100%;
  overflow-y: auto;
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
`;

export const challengeBtn = css`
  text-transform: uppercase;
`;
