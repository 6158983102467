import React, { useState, memo } from "react";
import { useInterval } from "../hooks/use-interval";
import Moment from "moment";
import "moment-timezone";

// import * as SS from "../styles/shared";
import * as S from "./countdown.styles";
import Btn from "./btn";

const leftpad = count => ("00" + count).substring(count.length);

const days = diff => Math.floor(diff / (1000 * 60 * 60 * 24));
const hours = diff =>
  Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
const mins = diff => Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
const secs = diff => Math.floor((diff % (1000 * 60)) / 1000);

const Countdown = ({ title, date, tick }) => {
  const then = Moment.tz(`${date} 17:00`, "America/New_York").unix() * 1000;
  const getCount = () => then - new Date().getTime();
  const [timer, setTimer] = useState(getCount());
  const updateTimer = () => setTimer(getCount());

  useInterval(updateTimer, tick);

  return (
    <S.CDBox as="section">
      <S.CDContent>
        <S.CDTtle>{title}</S.CDTtle>
        <S.CDTime>
          <span>
            <b>{days(timer)}</b>d
          </span>
          <span>
            <b>{leftpad("" + hours(timer))}</b>h
          </span>
          <span>
            <b>{leftpad("" + mins(timer))}</b>m
          </span>
          <span>
            <b>{leftpad("" + secs(timer))}</b>s
          </span>
        </S.CDTime>
        <Btn label="Share This" invert url="#share" />
      </S.CDContent>
    </S.CDBox>
  );
};

Countdown.defaultProps = {
  tick: 1000
};

export default memo(Countdown);
