import { useStaticQuery, graphql } from "gatsby";

const chellengeHeroImgQuery = graphql`
  query {
    image: file(relativePath: { eq: "placeholder/hero-signup-light.jpg" }) {
      ...ImageHero
    }
  }
`;

export const useChallengeHeroData = () => {
  const { image } = useStaticQuery(chellengeHeroImgQuery);
  return {
    img: image,
    title: "I want to put a ding in the universe.",
    btn: {
      label: "Accept Challenge",
      url: "#accept-challenge"
    }
  };
};
