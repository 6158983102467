import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Container } from "../styles/shared";

const { black, white, aqua, darkGray } = colors;

export const CDBox = styled.div`
  padding: ${rem(80)} 0 ${rem(60)};
  background-color: ${black};
  color: ${white};
`;

export const CDContent = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CDTtle = styled.h2`
  margin-bottom: ${rem(70)};
  ${fontSize(20, 28)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
`;

export const CDTime = styled.p`
  margin: 0 0 ${rem(90)};
  ${fontSize(70, 70)};
  font-weight: ${fontWeights.book};
  color: ${darkGray};

  b {
    font-weight: ${fontWeights.black};
    color: ${aqua};
  }
`;
