import React, { memo } from "react";

import * as S from "./btn.styles";

const Btn = ({ url, label, ...props }) => (
  <S.Btn href={url} {...props}>
    {label}
  </S.Btn>
);

export default memo(Btn);
