import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Container } from "../styles/shared";
import activeBg from "../images/timeline-bg.jpg";

const { black, white } = colors;

export const TlnBox = styled(Container)`
  padding: ${rem(120)} 0;
`;

export const TlnTitle = styled.h3`
  ${fontSize(36, 50)};
  font-weight: ${fontWeights.medium};
  margin-bottom: ${rem(100)};
  text-align: center;
  max-width: ${rem(840)};
  margin-left: auto;
  margin-right: auto;
`;

export const TlnCrlList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0 auto ${rem(75)};

  @media (${desktop}) {
    display: flex;
    width: 100%;
    max-width: ${rem(960)};
    justify-content: space-between;
  }
`;

export const TlnCrlItem = styled.li`
  position: relative;
`;

export const tlnBGActive = ({ active }) => css`
  background-image: ${active ? `url(${activeBg})` : "transparent"};
  background-size: cover;
`;

export const TlnBar = ({ first, last }) => css`
  @media (${desktop}) {
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: ${(first && "50%") || 0};
      display: block;
      width: ${((first || last) && "50%") || "100%"};
      height: ${rem(10)};
      background: ${black};
      content: "";
      z-index: -1;
    }
  }
`;

export const TlnBtnWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(200)};
  height: ${rem(200)};
  border-radius: 100%;
  overflow: hidden;
  ${tlnBGActive};
  ${TlnBar};
`;

export const TlnBtn = styled.button`
  position: relative;
  display: flex;
  width: ${rem(90)};
  height: ${rem(90)};
  align-items: center;
  justify-content: center;
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  text-align: center;
  background-color: ${black};
  border: 0;
  color: ${white};
  border-radius: 100%;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 2;
`;

export const TlnCard = styled.div`
  @media (${desktop}) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${rem(340)};
    z-index: 1;
  }
`;

export const TlnCardContent = styled.p`
  margin: ${rem(220)} 0 0 0;
`;

export const TlnCardDate = styled.span`
  display: block;
  margin-bottom: ${rem(20)};
  ${fontSize(70, 70)};
  font-weight: ${fontWeights.black};
`;

export const TlnCardTxt = styled.span`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.book};
`;
