import React, { memo } from "react";

import * as SShared from "../styles/shared";
import * as SPages from "./page.styles";

// import Image from "./image";
import HeroBlock from "./hero-block";
import Steps from "./steps";
import Timeline from "./timeline";
import Countdown from "./countdown";

const SS = { ...SShared, ...SPages };

const nextDate = (phases, now) =>
  phases.map(({ date }) => date).find(date => Date.parse(date) >= now);

const PageHowToCompete = ({
  getStarted1,
  getStarted2,
  intro,
  steps,
  timeline,
  title,
  ...props
}) => {
  const nextTime = nextDate(timeline, Date.now());
  return (
    <article>
      <SS.PageBody>
        <SS.PageHeader css={SS.clipBox} as="header">
          <SS.PageCat as="h1">{title}</SS.PageCat>
          <SS.PageTitle as="p">{intro}</SS.PageTitle>
        </SS.PageHeader>
        {steps && <Steps steps={steps} />}
      </SS.PageBody>
      <HeroBlock {...getStarted1} />
      <Timeline phases={timeline} />
      {nextTime && (
        <Countdown date={nextTime} title={"Judges Are Deliberating"} />
      )}
      <HeroBlock {...getStarted2} />
      {console.debug(props)}
    </article>
  );
};

export default memo(data => {
  return <PageHowToCompete {...data} />;
});
