import React from "react";

import { useChallengesData } from "../hooks/use-challenges-data";

import * as SS from "../styles/shared";
import * as S from "./challenges-card-list.styles";

import CCard from "./challenge-card";

const ChallengesList = ({ challenges, excluded, title }) => (
  <SS.Container css={S.ccardContainer}>
    {title && <S.CCardListTitle>{title}</S.CCardListTitle>}
    <S.CCardList>
      {challenges
        .filter(({ url }) => excluded.indexOf(url) === -1)
        .map((challenge, idx) => (
          <CCard
            {...challenge}
            key={idx}
            image={challenge.cardImage}
            css={S.CCardLI}
          />
        ))}
    </S.CCardList>
  </SS.Container>
);

ChallengesList.defaultProps = {
  challenges: [],
  excluded: []
};

export default data => {
  const challenges = useChallengesData();
  return <ChallengesList {...data} challenges={challenges} />;
};
