import React from "react";

import ChallengeCardList from "./challenges-card-list";
import HeroBlock from "./hero-block";

import * as S from "./page.styles";

const PageChallenges = ({ title, intro, ctaBlock, challenges }) => (
  <S.PageBody as="article">
    <S.PageHeader as="header">
      <S.PageCat as="h1">{title}</S.PageCat>
      <S.PageTitle as="p">{intro}</S.PageTitle>
    </S.PageHeader>
    <ChallengeCardList />
    <HeroBlock {...ctaBlock} />
  </S.PageBody>
);

export default data => {
  return <PageChallenges {...data} />;
};
