import React, { memo } from "react";

import * as S from "./steps.styles";

const Step = ({ count, title, desc, img, odd, as }) => (
  <S.StepItem as={as} odd={odd}>
    <S.StepCount pad odd={odd}>
      <S.StepCountTxt>{("00" + count).substring(count.length)}</S.StepCountTxt>
      <S.StepImg data={img} />
    </S.StepCount>
    <S.StepContent odd={odd}>
      <S.StepTitle>{title}</S.StepTitle>
      <S.StepText>{desc}</S.StepText>
    </S.StepContent>
  </S.StepItem>
);

const Steps = ({ steps }) => (
  <S.StepList as={"ol"}>
    {steps.map((step, idx) => (
      <Step key={idx} count={"" + (idx + 1)} odd={idx % 2} {...step} as="li" />
    ))}
  </S.StepList>
);

Steps.defaultProps = {
  steps: []
};

export default memo(Steps);
