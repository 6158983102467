import React, { memo, useState } from "react";

import * as S from "./challenge-card.styles";
import Image from "./image";
import Btn from "./btn";

const CardFront = ({ image, sgd, title, color, flipped, setFlipped }) => (
  <S.CardSide hidden={flipped} onClick={() => setFlipped(true)}>
    <S.CardBg color={color} />
    {image && (
      <>
        <S.CardImgOverlay />
        <Image
          css={S.cardBgImg}
          data={image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      </>
    )}
    <S.CardTitleBox>
      <span css={S.headingTxt}>{sgd}</span>
      <S.CardTitle>{title}</S.CardTitle>
    </S.CardTitleBox>
  </S.CardSide>
);

CardFront.defaultProps = {
  color: "#000000"
};

const CardBack = ({ sponsor, summary, url, flipped, setFlipped }) => (
  <S.CardSide hidden={!flipped}>
    <S.CardInfo onClick={() => setFlipped(false)}>
      <h3 css={S.headingTxt}>Challenge</h3>
      <p css={S.challengeTxt}>{summary}</p>
      <p css={S.headingTxt}>{sponsor}</p>
    </S.CardInfo>
    <Btn css={S.challengeBtn} url={url} label="Learn More" />
  </S.CardSide>
);

const ChallengeCard = ({ className, ...props }) => {
  const [flipped, setFlipped] = useState(false);
  return (
    <S.Card className={className}>
      <CardFront {...props} flipped={flipped} setFlipped={setFlipped} />
      <CardBack {...props} flipped={flipped} setFlipped={setFlipped} />
    </S.Card>
  );
};

export default memo(ChallengeCard);
