import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";

const { white, black } = colors;

const wideBtn = css`
  min-width: ${rem(228)};
`;

export const invert = ({ invert }) => {
  return (
    invert &&
    css`
      background-color: ${white};
      color: ${black};

      &:focus,
      &:active,
      &:hover {
        color: ${black};
      }
    `
  );
};

export const Btn = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(15)};
  color: ${white};
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  background-color: ${black};
  min-width: ${rem(160)};
  ${({ wide }) => wide && wideBtn};
  text-transform: uppercase;
  border-radius: ${rem(30)};

  &:focus,
  &:active,
  &:hover {
    color: ${white};
  }

  ${invert};
`;
