import React, { memo } from "react";
import Img from "gatsby-image/withIEPolyfill";

const GatsbyImage = ({ className, fixed, fluid, aspectRatio, ...props }) => {
  if (fixed) {
    return <Img className={className} fixed={fixed} {...props} />;
  }

  if (fluid) {
    return (
      <Img
        className={className}
        fluid={{ ...fluid, ...(!!aspectRatio && { aspectRatio }) }}
        {...props}
        presentationWidth={fluid.presentationWidth}
      />
    );
  }

  return <></>;
};

const Image = ({ data: { childImageSharp, image }, ...props }) => {
  if (!!image && typeof image === "string") {
    return <img src={image} alt={props.alt} />;
  }

  if (!!childImageSharp) {
    return <GatsbyImage {...childImageSharp} {...props} />;
  }

  return <></>;
};

Image.defaultProps = {
  data: {},
  alt: ""
};

export default memo(Image);
