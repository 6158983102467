import React, { useState, useRef, useEffect, memo } from "react";
import Moment from "moment";

import * as S from "./timeline.styles";

const ActiveCard = ({ date, text, hidden }) => {
  const cardEl = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!hidden && !height && cardEl && cardEl.current) {
      setHeight(cardEl.current.getBoundingClientRect().height);
    }
  }, [hidden]);

  return (
    <>
      <S.TlnCard hidden={hidden} ref={cardEl}>
        <S.TlnCardContent>
          <S.TlnCardDate>
            {Moment(new Date(date)).format("D MMM")}
          </S.TlnCardDate>
          <S.TlnCardTxt>{text}</S.TlnCardTxt>
        </S.TlnCardContent>
      </S.TlnCard>
      <div style={{ height }} />
    </>
  );
};

const TimelineControl = ({ phases, setActive, active }) => (
  <S.TlnCrlList>
    {phases.map(({ label, ...props }, idx) => (
      <S.TlnCrlItem key={`tl-${idx}`}>
        <S.TlnBtnWrap
          active={active === idx}
          first={idx === 0}
          last={idx === phases.length - 1}
        >
          <S.TlnBtn
            onClick={() => setActive(idx)}
            onMouseDown={e => {
              e.preventDefault();
              e.target.blur();
            }}
          >
            {label}
          </S.TlnBtn>
        </S.TlnBtnWrap>
        <ActiveCard hidden={active !== idx} {...props} />
      </S.TlnCrlItem>
    ))}
  </S.TlnCrlList>
);

TimelineControl.defaultProps = {
  phases: []
};

const Timeline = ({ phases }) => {
  const [active, setActive] = useState(0);
  const { title } = phases[active];

  return (
    <S.TlnBox as="section">
      <S.TlnTitle>{title}</S.TlnTitle>
      <TimelineControl phases={phases} setActive={setActive} active={active} />
    </S.TlnBox>
  );
};

Timeline.defaultProps = {
  phases: []
};

export default memo(Timeline);
