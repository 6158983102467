import React, { memo } from "react";

import Btn from "./btn";
import * as S from "./hero-block.styles";

const HeroBlock = ({ className, title, img, btn, big, color }) => (
  <S.HeroBG
    className={className}
    Tag="aside"
    fluid={img.childImageSharp.fluid}
    big={big}
  >
    <S.HeroContent big={big}>
      <S.HeroTitle big={big} color={color}>
        {title}
      </S.HeroTitle>
      <Btn wide={big} {...btn} />
    </S.HeroContent>
  </S.HeroBG>
);

export default memo(HeroBlock);
