import { useStaticQuery, graphql } from "gatsby";

const challengesQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/challenges/.*$/" } }
    ) {
      nodes {
        ...ChallengeData
        fields {
          url: slug
        }
      }
    }
  }
`;

export const useChallengesData = () => {
  const {
    allMarkdownRemark: { nodes }
  } = useStaticQuery(challengesQuery);
  return nodes.map(({ frontmatter, fields }) => ({
    ...frontmatter,
    ...fields
  }));
};
