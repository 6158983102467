import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights } from "../styles/utils";

const gutter = 65;

export const CCardListTitle = styled.h2`
  margin-top: ${rem(80)};
  margin-bottom: 0;
  ${fontSize(30, 40)};
  font-weight: ${fontWeights.bold};
  text-align: center;
  margin-bottom: ${rem(100 - gutter)};
`;

export const CCardList = styled.div`
  margin: 0 0 ${rem(80)};

  @media (min-width: ${rem(650)}) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${rem(gutter * -1)} ${rem(120)};
  }
`;

export const CCardLI = css`
  margin: ${rem(gutter * 0.5)} auto;

  @media (min-width: ${rem(650)}) {
    margin: ${rem(gutter)};
  }
`;

export const ccardContainer = css`
  overflow: hidden;
`;
