import { useStaticQuery, graphql } from "gatsby";

const placeholderImageQuery = graphql`
  query {
    challengeHero: file(
      relativePath: { eq: "placeholder/hero-signup-light.jpg" }
    ) {
      ...ImageHero
    }
    hero: file(relativePath: { eq: "placeholder/hero-signup-dark.jpg" }) {
      ...ImageHero
    }
    avatar: file(relativePath: { eq: "placeholder/avatar-photo.jpg" }) {
      ...ImageAvatar
    }
  }
`;

export const usePlaceholderImages = () => {
  const images = useStaticQuery(placeholderImageQuery);
  return images;
};
