import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import PageLegal from "../components/page-legal";
import PageChallenges from "../components/page-challenges";
import PageChallenge from "../components/page-challenge";
import PageHowToCompete from "../components/page-how-to-compete";

const PageTemplate = ({ seo, template, ...props }) => (
  <Layout title={props.title} {...seo}>
    {template === "challenges" && <PageChallenges {...props} />}
    {template === "challenge" && <PageChallenge {...props} />}
    {template === "legal" && <PageLegal {...props} />}
    {template === "how-to-compete" && <PageHowToCompete {...props} />}
  </Layout>
);

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      ...ChallengeData
      ...CTABlock
      ...PageSeo
      ...PageData
      ...HowToCometeData
      html
      fields {
        slug
      }
    }
  }
`;

export default ({
  data: {
    page: { frontmatter, html, fields }
  }
}) => <PageTemplate html={html} {...frontmatter} {...fields} />;
