import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { rem, fontSize, fontWeights } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Container } from "../styles/shared";

import Image from "./image";

const rowStyle = ({ odd }) => css`
  @media (${desktop}) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${odd ? "row-reverse" : "row"};
  }
`;

const colStyle = css`
  @media (${desktop}) {
    display: flex;
    width: 50%;
  }
`;

const flexRow = ({ odd }) => css`
  @media (${desktop}) {
    justify-content: flex-end;
    flex-direction: ${odd ? "row-reverse" : "row"};
    padding: 0 ${rem(60)};
    padding-left: ${!odd ? 0 : rem(120)};
    padding-right: ${odd ? 0 : rem(120)};
  }
`;

const flexCol = ({ odd }) => css`
  @media (${desktop}) {
    flex-direction: column;
    text-align: ${odd ? "right" : "left"};
    align-items: ${odd ? "flex-end" : "flex-start"};
  }
`;

export const StepList = styled(Container)`
  list-style: none;
  margin-bottom: ${rem(120)};
`;

export const StepItem = styled.div`
  padding-top: ${rem(45)};
  padding-bottom: ${rem(16)};
  ${rowStyle};
`;

export const StepImg = styled(Image)`
  border-radius: 100%;
  width: ${rem(220)};
  overflow: hidden;
`;

export const StepCount = styled.div`
  ${colStyle};
  ${flexRow};
`;

export const StepContent = styled.div`
  ${colStyle};
  ${flexCol};
`;

export const StepCountTxt = styled.span`
  display: block;
  ${fontSize(70, 70)};
  font-weight: ${fontWeights.black};
  margin: ${rem(-10)};
`;

export const StepTitle = styled.h2`
  ${fontSize(24, 30)};
  font-weight: ${fontWeights.black};
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
  max-width: ${rem(500)};
  text-transform: uppercase;
`;

export const StepText = styled.p`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.book};
  margin: 0;
  max-width: ${rem(500)};
`;
